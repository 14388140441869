.thumbsContainer {
    display: flex;
    margin-top: 16px;
    width: 100%;
    flex-wrap: wrap;
}

.thumbInner {
    float: left;
    position: relative;
    margin-right: 10px;
    margin-bottom: 16px;
}

.thumbImage {
    float: left;
    height: 80px;
    width: 80px;
    border-radius: 0.357rem;
    overflow: hidden;
}

.thumbImageRemove {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.60);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0.357rem;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.thumbImageRemove:hover {
    opacity: 1;
}

.thumbImageRemove {
    float: right;
}

.thumbImageRemove i {
    float: right;
    color: white;
    margin-top: 5px;
    margin-right: 5px;
    font-size: 18px;
}
