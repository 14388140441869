/* CUSTOM CSS */

.main-menu.menu-light .navigation > li a.active {
  background: -webkit-linear-gradient(332deg, #7367f0, rgba(115, 103, 240, 0.7));
  background: linear-gradient(118deg, #7367f0, rgba(115, 103, 240, 0.7));
  box-shadow: 0 0 10px 1px rgba(115, 103, 240, 0.7);
  border-radius: 4px;
  z-index: 1;
}

.main-menu.menu-light .navigation li a.active {
  color: #ffffff;
}

.avatar-group {
  display: inline;
}

.avatar-group .avatar {
  margin-right: 0.5em;
  background-color: #dcdee0;
}

.badge {
  text-transform: capitalize;
}

.dropzone .dz-message {
  font-size: 1em;
}

.dropzone .dz-message:before {
  width: 40px;
  height: 40px;
}

img#account-upload-img {
  object-fit: cover;
}

.ecommerce-application .list-view .ecommerce-card .item-img {
  width: 280px !important;
}

.ecommerce-application .list-view .ecommerce-card .item-img img {
  height: 200px !important;
}
